<template>
  <!-- <div>
    <h2>
      {{ $t('reportError.completePage.description') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col cols="10">
        <v-textarea
          v-model="description"
          clearable
          clear-icon="mdi-close-circle"
          :label="$t('reportError.completePage.label')"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          class="ml-3"
          color="primary"
          elevation="6"
          x-large
          @click="reportError()"
        >
          {{ $t('reportError.completePage.action') }}
        </v-btn>
      </v-col>
    </v-row>
  </div> -->
  <div class="error-report-wrapper">
    <div class="error-report-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-report.machines-report.description') }}</h4>
      </div>
      <loading-spinner
        v-if="isLoading"
        :size="60"
      />
      <div
        v-if="!isLoading && isStopped"
        class="stop-state"
      >
        <p class="text">
          {{ $t('error-report.machines-report.isStopped.text1') }}
          <span class="bold">{{ getMachineName }}</span>
          {{ $t('error-report.machines-report.isStopped.text2') }}
          <router-link
            :to="({ name: 'ErrorDetailMachine', params: { id: stopStateErrorId } })"
          >
            #{{ stopStateErrorId }}-{{ getMachineName }}
          </router-link>
          .
        </p>
      </div>
      <div
        v-if="!isLoading && !isStopped"
        class="description"
      >
        <ul class="summary">
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.categorySummary') }}
            </p>
            <p
              v-if="$route.path.includes('machine/report')"
              class="value"
            >
              {{ $t('error-report.machines') }}
            </p>
            <p
              v-if="$route.path.includes('car')"
              class="value"
            >
              {{ $t('error-report.cars') }}
            </p>
            <p
              v-if="$route.path.includes('it')"
              class="value"
            >
              {{ $t('error-report.it') }}
            </p>
            <p
              v-if="$route.path.includes('marketing')"
              class="value"
            >
              {{ $t('error-report.marketing') }}
            </p>
          </li>
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.placeSummary') }}
            </p>
            <p class="value">
              {{ getHallName }}
            </p>
          </li>
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.typeSummary') }}
            </p>
            <p class="value">
              {{ getTypeName }}
            </p>
          </li>
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.machineSummary') }}
            </p>
            <p class="value">
              {{ getMachineName }}
            </p>
          </li>
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.errorTypeSummary') }}
            </p>
            <p class="value">
              {{ getCategoryName }}
            </p>
          </li>
          <li class="item">
            <p class="item-title">
              {{ $t('error-report.machines-report.flawLevelSummary') }}
            </p>
            <p
              v-if="getFlawlevel === 'STOPPED'"
              class="value stopped"
            >
              <img
                :src="CardIcon1"
                alt="Icon"
                class="icon-image"
              >
              {{ $t('error-report.machines-report.stopped') }}
            </p>
            <p
              v-if="getFlawlevel === 'LIMITED'"
              class="value limited"
            >
              <img
                :src="CardIcon2"
                alt="Icon"
                class="icon-image"
              >
              {{ $t('error-report.machines-report.limited') }}
            </p>
            <p
              v-if="getFlawlevel === 'POOR_QUALITY'"
              class="value poor-quality"
            >
              <img
                :src="CardIcon3"
                alt="Icon"
                class="icon-image"
              >
              {{ $t('error-report.machines-report.poorQuality') }}
            </p>
            <p
              v-if="getFlawlevel === 'PLANNED_MAINTENANCE'"
              class="value planned-maintenance"
            >
              <img
                :src="CardIcon4"
                alt="Icon"
                class="icon-image"
              >
              {{ $t('error-report.machines-report.plannedMaintenance') }}
            </p>
            <p
              v-if="getFlawlevel === 'RUNNING'"
              class="value running"
            >
              <img
                :src="CardIcon5"
                alt="Icon"
                class="icon-image"
              >
              {{ $t('error-report.machines-report.running') }}
            </p>
          </li>
        </ul>
        <div class="desc-area">
          <textarea
            id="description"
            v-model="description"
            name="description"
            autofocus
            class="description-area"
            :placeholder="$t('error-report.machines-report.descriptionPlaceholder')"
          />
        </div>
        <div class="buttons">
          <custom-button @click="reportError()">
            {{ $t('error-report.machines-report.sendButton') }}
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonIcon from '@/assets/arrow_button.svg'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'

export default {
  name: 'AddDescription',
  components: {
    CustomButton,
    LoadingSpinner
  },
  data () {
    return {
      ButtonIcon,
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      description: '',
      isLoading: true,
      isStopped: null,
      stopStateErrorId: null,
      filteredData: {}
    }
  },
  computed: {
    ...mapGetters('ErrorReport', ['getHallName', 'getTypeName', 'getMachineName', 'getCategoryName', 'getFlawlevel'])
  },
  created () {
    if (this.$route.params.type === 'MACHINE' && this.$route.params.flawLevel === 'STOPPED') {
      console.log('ide getTasksData')
      this.getTasksData()
    } else {
      console.log('nejde getTasksData')
      this.isLoading = false
      this.isStopped = false
    }
  },
  methods: {
    async reportError () {
      try {
        const params = this.$route.params
        await this.$axios.post(
          '/machine-errors',
          {
            hallId: params.hallId,
            machineId: params.machineId,
            errorCategoryId: params.categoryId,
            description: this.description,
            machineStatus: params.flawLevel
          }
        )
        // this.$router.push({ name: 'successInfo' })
        this.$router.push({ name: 'CurrentMachineErrors' })
        this.$store.commit('ErrorReport/RESET')
      } catch (err) {
        console.error(err)
      }
    },
    async getTasksData () {
      try {
        const { data } = await this.$axios.get('/machine-errors/')

        for (let i = 0; i < data.length; i++) {
          if (!data[i].finishedAt && data[i].machineStatus === 'STOPPED' && data[i].machineId === Number(this.$route.params.machineId)) {
            this.filteredData = data[i]
            console.log(this.filteredData)
          }
        }

        if (this.filteredData.machineId === Number(this.$route.params.machineId)) {
          this.isStopped = true
          this.stopStateErrorId = this.filteredData.id
        } else {
          this.isStopped = false
        }
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-report-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 53px;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }

    div.stop-state {
      width: 100%;
      max-width: 541px;
      margin-top: 64px;
      background-color: $primary-white;
      padding: 22px 24px;

      p.text {
        font-weight: 500;

        span.bold {
          font-weight: 700;
        }
      }
    }

    div.description {
      width: 100%;
      max-width: 541px;
      margin-top: 64px;
      background-color: $primary-white;

      ul.summary {
        padding: 0;
        margin: 0;
        list-style-type: none;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #F8F8F8;
        display: flex;
        flex-direction: column;

        li.item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 22px 0;
          margin: 0 24px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          &:last-child {
            border-bottom: none;
          }

          p.item-title {
            margin: 0;
            color: $primary-grey;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }

          p.value {
            display: flex;
            align-items: center;
            margin: 0;
            color: $primary-black;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;

            &.stopped {
              color: $primary-red;
            }

            &.limited,
            &.poor-quality {
              color: $status-yellow;
            }

            &.planned-maintenance,
            &.running {
              color: $status-green;
            }

            img.icon-image {
              margin-right: 8px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      div.desc-area {
        width: 100%;
        padding: 28px 24px;

        textarea.description-area {
          width: 100%;
          min-height: 120px;
          padding: 16px;
          color: rgba(0, 0, 0, 0.87);
          border: 1px solid rgba(0, 0, 0, 0.42);
          outline: none;
          resize: none;
        }
      }

      div.buttons {
        width: 100%;
        padding: 20px 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-report-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-report-container {
      padding: 0;

      div.top-part {
        margin-top: 0;
        padding: 24px;

        img.back-button {
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }

      div.description,
      div.stop-state {
        max-width: 100%;
        margin-top: 0;
      }
    }
  }
}
</style>
